<template>
  <div class="user_content">
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @select="onGo"
      @add="onAdd"
      @edit="onEdit"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { AddressList, Toast } from 'vant'

Vue.use(AddressList).use(Toast)
import { addressH5List } from '@/services/userApi'

export default {
  data() {
    return {
      chosenAddressId: '',
      list: [],
      type: ''
    }
  },
  mounted() {
    this.type = this.$route.query.type
    if (localStorage.getItem('uid') && localStorage.getItem('token')) {
      this.getAddresslist()
    } else {
      Toast('用户未登录')
    }
  },
  methods: {
    // 添加地址按钮
    onAdd() {
      this.$router.push({ path: '/editAddress' })
    },
    // 修改
    onEdit(item, index) {
      this.$router.push({ path: '/editAddress', query: item })
    },
    // 选择地址跳转
    onGo(item, index) {
      item.mobile = item.tel
      item.receiverName = item.name
      if (this.type !== 1) {
        const _address = JSON.stringify(item)
        localStorage.setItem('addressItem', _address)
        this.$store.commit('handleAddressInfo', JSON.parse(_address))
        this.$router.go(-1)
      } else {
        this.$router.push({ path: '/editAddress', query: item })
      }
    },
    // 获取地址列表
    getAddresslist() {
      const _self = this
      const _uid = localStorage.getItem('uid')
      const params = {
        mid: _uid
      }
      addressH5List(params).then((res) => {
        const _chosenAddress = res.data.filter((val) => {
          if (this.$route.query.id) {
            return val.id === this.$route.query.id
          } else {
            return val.isDefault === 1
          }
        })
        if (_chosenAddress.length) {
          _self.chosenAddressId = _chosenAddress[0].id
        }
        _self.list = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  //height: -moz-calc(100% - 50px);
  //height: -webkit-calc(100% - 50px);
  //height: calc(100% - 50px);
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
}

//强改
/deep/ .van-nav-bar {
  .van-icon {
    color: #000;
  }
}

/deep/ .van-radio-group {
  padding-bottom: 120px;
}

/deep/ .van-address-list__bottom {
  position: fixed;
  bottom: 0;
}

</style>
